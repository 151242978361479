.work{
    background-color: #000000;
    margin-top: -30px;
}
.homehandle{
padding: 92px;
}
img{
    max-width: 100% !important;
}

.textstype{
    margin-top: 20px;
    font-size: 18px;
    line-height: 30px;
}


.longimg{
    margin-top: 64px;
    position: absolute;
    width: 100%;
    margin-left: -26px;
}

.textdatax{
    color: white;
    margin-top: 173px;
    font-size: 23px;
    text-align: center;
    margin-bottom: 48px;
}
.txt{
    display: flex;
    justify-content: center;
    align-items: center;
}
.teexxt{
 width: 75%;
}


/* home contact css */

/* .contact-info {
    padding: 0px 50px;
} */

.contact-heading{
    padding: 20px 20px 10px 20px;
    margin: 0 35px;
    /* background-color: #ffab00; */
    text-align: center;
    color: black;
    background-image: linear-gradient(45deg, #ff8d00, #ffeb00);
}
form#contact {
    padding: 30px;
    margin: 0 35px;
    background-color: white;
}
form#contact .input {
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border: none;
    outline: 1px solid #dddddd;
}
form#contact .input:focus { 
    outline: 1px solid red;
}
button#contact-submit {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: none;
    background: red;
    color: white;
}

.btnone-1 button {
    padding: 8px 20px;
    background-color: red;
    border-radius: 50px;
    border: none;
    margin-right: 20px;
    width: 100%;

}
.btnone-1 button a {
    text-decoration: none;
    color: white;
}
.btnone-2 button {
    padding: 8px 20px;
    background-color: #ff8d00;
    border-radius: 50px;
    border: none;
    margin-right: 20px;
    width: 100%;
}
.btnone-2 button a {
    text-decoration: none;
    color: white;
}
@media only screen and (max-width: 600px) {
    .homehandle{
        padding: 25px;
        }

    }
    