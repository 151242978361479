.htl-banner{
   background-image: url('../../../public/picture/Rectangle\ 9.png');
   width: 100%;
   background-size: cover;
   padding: 150px 0;
   background-repeat: no-repeat;
   background-position: center center;
}

.hotelimg {
   padding: 20px;
}

.content-box .heading-1 {
   color: white;
   font-size: 34px;
}

.content-box .heading-2 {
   color: white;
   font-size: 24px;
   margin-bottom: 20px;
}

.content-box .book-para {
   color: white;
   font-size: 16px;
}

@media only screen and (max-width: 600px) {
   .hotelimg {
      padding: 1px;
   }
}