.lease{
    padding: 55px;
    margin-bottom: 60px;
   }
   .content-box .heading-1 {
      color: white;
      font-size: 34px;
  }
   .content-box .heading-2 {
      color: white;
      font-size: 24px;
      margin-bottom: 20px;
  }
   .content-box .leasing-para {
      color: white;
      font-size: 16px;
  }
  .coporate-image img{
   margin-bottom: 20px;
  }

@media only screen and (max-width: 600px) {
   .lease{
    padding: 10px;
   }
}