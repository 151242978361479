.hotel-banner{
    background-image: url('../../../public/picture/hotel-background-image.jpg');
    width: 100%;
    background-size: cover;
    padding: 100px 0;
    background-repeat: no-repeat;
    background-position: center center;
}
.search-box input[type="text"] {
    padding: 10px 24px;
    border: none;
    width: 55%;
    color: black;
    background: white;
    border-radius: 30px;
    outline: none;
}
.search-box button {
    padding: 10px 10px 10px 10px;
    border-radius: 0 28px 28px 0;
    width: 13%;
    border: none;
    background-color: orange;
    margin-left: -35px;
    color: black;
}
.search-box button:hover {
    background-color: black;
    transition: all 0.5s ease;
    color: white;
}
.card-box {
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    height: 365px;
}
.card-box .image-box img {
    border-radius: 15px;
    width: 100%;
    height: 215px;
    cursor: pointer;
}
.card-box .image-box{
    overflow: hidden;
    border-radius: 15px;
}
.card-box .image-box img:hover {
    transition: all 0.5s ease;
    transform: scale(110%);
    overflow: hidden;
}
.inner-content {
    display: flex;
}
.inner-content .in-content {
    width: 60%;
}
.inner-content .price {
    width: 40%;
    text-align: end;
    font-size: 22px;
    color: black;
    font-weight: bold;
}
.hotel-ban-heading{
    font-size: 42px;
}
.box-content .hotel-name{
    font-size: 22px;
}

@media (max-width: 575px) {
    .search-box {
        width: 100%;
    }
    .search-box input[type="text"] {
        width: 70%;
    }
    .search-box button {
        width: 20%;
    }
}
@media (max-width: 400px) {
    .search-box input[type="text"] {
        width: 80%;
    }
    .search-box button {
        width: 20%;
    }
}