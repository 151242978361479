.content-box .heading-1 {
    color: white;
    font-size: 34px;
}
 .content-box .heading-2 {
    color: white;
    font-size: 24px;
    margin-bottom: 20px;
}
 .content-box .coporate-para {
    color: white;
    font-size: 16px;
}
 .content-box ul li{
    color: white;
    font-size: 16px;
}