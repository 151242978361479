.hotel-bann{
    background-color: black;
}
.hotel-background{
    background-image: url('../../../public/picture/Rectangle 9.png');
    width: 100%;
    background-size: cover;
    padding: 100px 0;
    background-repeat: no-repeat;
    background-position: bottom center;
}
.bann-heading{
    color: white;
    font-size: 50px;
}
.hh-inner{
    background-color: white;
    padding: 20px;
    overflow: hidden !important;
}
.main-image{
    height: 350px !important;
}
.img-box-content p.hotel-name {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
}
